import React, { FC, useState, useCallback, useEffect, useRef } from 'react'
import { Box, Container, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import Link from '@mui/material/Link';
import iconCall from '../../../assets/images/icons/call.svg';
import iconPin from '../../../assets/images/icons/pin.svg';
import iconEmail from '../../../assets/images/icons/email.svg';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { fetchDeviceType, fetchMaterialRequirement, fetchSpecialFeature, fetchStreeableMechanism, fetchStructures, postFormDataForAdvCathetersRFQ } from '../../../Service/AdvancedCathetersSteerablesRFQ';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

interface userContactInformation {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    DeviceType: string;
    StreeableMechanism: string;
    MaterialRequirement: string;
    DeviceDimensions: string;
    QuantitiesNeed: string;
    Structures: string;
    SpecialFeature: string;
    BudgetRange: string;
    DeliveryTimeline: string;
    SubAssembly: string;
}


export interface ErrorMessage {
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    DeviceType: string;
    StreeableMechanism: string;
    MaterialRequirement: string;
    DeviceDimensions: string;
    QuantitiesNeed: string;
    Structures: string;
    SpecialFeature: any;
    BudgetRange: string;
    DeliveryTimeline: string;
    SubAssembly: string;
}

const initial_value: userContactInformation = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    DeviceType: '',
    StreeableMechanism: '',
    MaterialRequirement: '',
    DeviceDimensions: '',
    Structures: '',
    QuantitiesNeed: '',
    SpecialFeature: '',
    BudgetRange: '',
    DeliveryTimeline: '',
    SubAssembly: ''
}

interface ApiResponseDropdown {
    body: string[],
    loading: boolean
}

const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;


const AdvRFQInfusionSection = (props: any) => {
    const { data } = props;
    const [contactInputField, setContactInputField] = useState<userContactInformation>(initial_value);
    const [loading, setLoading] = useState<boolean>(true);
    const [phone, setPhone] = useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedApplication, setSelectedApplication] = useState<string>('');
    const myRef = useRef(null);
    const scrollToRef = (ref: any) => window.scrollTo(0, 1200);
    const [deviceTypeApiResponse, setDeviceTypeApiResponse] = useState<ApiResponseDropdown>({ loading: true, body: [] })
    const [streeableMechanismApiResponse, setStreeableMechanismApiResponse] = useState<ApiResponseDropdown>({ loading: true, body: [] });
    const [structuresApiResponse, setStructuresmApiResponse] = useState<ApiResponseDropdown>({ loading: true, body: [] });
    const [materialRequirementApiResponse, setMaterialRequirementApiResponse] = useState<ApiResponseDropdown>({ loading: true, body: [] });
    const [specialFeatureApiResponse, setSpecialFeatureApiResponse] = useState<ApiResponseDropdown>({ loading: true, body: [] });

    const [error, setError] = useState<ErrorMessage>({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        DeviceType: '',
        StreeableMechanism: '',
        MaterialRequirement: '',
        DeviceDimensions: '',
        Structures: '',
        QuantitiesNeed: '',
        SpecialFeature: '',
        BudgetRange: '',
        DeliveryTimeline: '',
        SubAssembly: ''
    });

    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchDeviceType();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setDeviceTypeApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            throw err
        })
    }, [])

    const reconstructArray = (array: any) => {
        const result = [];
        let tempString = "";

        array.forEach((item: any) => {
            tempString += item.trim();
            if (tempString.endsWith(")") || !tempString.includes("(")) {
                result.push(tempString.trim());
                tempString = "";
            } else {
                tempString += ", ";
            }
        });
        if (tempString) {
            result.push(tempString.trim());
        }
        return result;
    };


    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchStreeableMechanism();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            const splitArray = dummyString.split(",");
            console.log("Original split array:", splitArray);

            // Fix the broken strings
            const reconstructedArray = reconstructArray(splitArray);
            console.log("Reconstructed array:", reconstructedArray);
            setStreeableMechanismApiResponse({ loading: false, body: reconstructedArray })
        }).catch((err) => {
            throw err
        })
        console.log('streeableMechanismApiResponse', streeableMechanismApiResponse)
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchStructures();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setStructuresmApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            throw err
        })
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchMaterialRequirement();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setMaterialRequirementApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            throw err
        })
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchSpecialFeature();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setSpecialFeatureApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            throw err
        })
    }, [])


    const handelInputChange = (e: any) => {
        const { name, value, id } = e.target;
        const re = /^\S*$/;
        let errors: ErrorMessage = error;
        setContactInputField({ ...contactInputField, [name]: value, [id]: value })
        if (name === 'firstName') {
            if (!value) {
                setError({ ...error, [name]: "Please add valid name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid name" })

            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (name === 'lastName') {
            if (!value) {
                setError({ ...error, [name]: "Please add valid Last name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid last name" })
            }

        } else if (name === 'email') {
            if (!value) {
                setError({ ...error, [name]: "Please enter your email Address." })
            } else {
                setError({ ...error, [name]: "Please fill email field" })
            }
            if (typeof value !== "undefined") {
                if (!value) {
                    // isValid = false;
                    // errors["email"] = "Please enter valid email address.";
                    setError({ ...error, [name]: "Please enter valid email address." })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        }
        else if (name === "DeviceType") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
        else if (name === "StreeableMechanism") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
        else if (name === "MaterialRequirement") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
        else if (name === "Structures") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
        else if (name === "SpecialFeature") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
    }

    // const handleFileUpload = async (e: any) => {
    //     const file = e.target.files[0];
    //     console.log("Uploaded file:", file);

    //     if (file) {
    //         // Set file name for UI display
    //         setSelectedFileName(file.name);

    //         // Set the actual file in the contact input field state (if necessary)
    //         setContactInputField(prevState => ({
    //             ...prevState,
    //             SpecialFeature: file.name, // Store the filename instead of the file object
    //         }));

    //         // You can upload the filename to the server right here if needed
    //         await SpecialFeature(file); // Pass the actual file object to the upload function
    //     }
    // };

    // const SpecialFeature = async (file: File) => {
    //     try {
    //         // Set the file path (with folder name) for S3
    //         const payloadFile = `complexExtrusions_file/${file.name}`;
    //         console.log("Payload file path:", payloadFile);

    //         // Request the pre-signed URL from the backend (Lambda)
    //         const response = await axios.post('https://jn6xcswqv0.execute-api.us-east-1.amazonaws.com/dev/upload',
    //             { filename: payloadFile },
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'filetype': 'multipart/form-data',
    //                 }
    //             }
    //         );

    //         console.log("Response:", response);

    //         // Extract the pre-signed URL and fields from the backend response
    //         const { url, fields, message } = response.data.data;  // Correct extraction

    //         if (url && fields) {
    //             console.log("Pre-signed URL:", url);
    //             console.log("Fields:", fields);

    //             // Prepare the form data
    //             const formData = new FormData();
    //             formData.append('key', fields.key);  // Add the key from the fields
    //             formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);  // Add the AccessKeyId
    //             formData.append('policy', fields.policy);  // Add the policy
    //             formData.append('signature', fields.signature);  // Add the signature
    //             formData.append('file', file);  // Add the actual file

    //             // Step 2: Upload the file to S3 using the pre-signed URL
    //             const uploadResponse = await axios.post(url, formData, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //             });

    //             if (uploadResponse.status === 204) {
    //                 console.log('File uploaded successfully to S3');

    //                 try {
    //                     const res = await axios.post('https://jn6xcswqv0.execute-api.us-east-1.amazonaws.com/dev/make_public',
    //                         { filename: payloadFile },
    //                         {
    //                             headers: {
    //                                 'Content-Type': 'application/json',
    //                                 'filetype': 'multipart/form-data',
    //                             }
    //                         }
    //                     );

    //                     console.log("Get the public access to the link", res);

    //                 } catch (error) {
    //                     console.error('Failed to get public access:', error);
    //                 }
    //             }

    //             else {
    //                 console.error('File upload failed:', uploadResponse.statusText);
    //             }
    //         } else {
    //             console.error('Failed to get pre-signed URL or fields:', message);
    //         }
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };


    const handelSubmit = (e: React.SyntheticEvent) => {
        // window.scrollTo(0,1200);
        scrollToRef(myRef);
        let screen_width = window.screen.width;
        if (screen_width < 768) {
            window.scrollTo(0, 2000);
        }

        e.preventDefault();

        const contactPayload = {
            "firstname": contactInputField.firstName,
            "lastname": contactInputField.lastName,
            "company": contactInputField.company,
            "email": contactInputField.email,
            "Type_of_Catheter_Device__c": contactInputField.DeviceType,
            "Steerable_Mechanism__c": contactInputField.StreeableMechanism,
            "Material_Requirements__c": contactInputField.MaterialRequirement,
            "Device_Dimensions_Tolerances__c": contactInputField.DeviceDimensions,
            "Quantities_Needed__c": contactInputField.QuantitiesNeed,
            "Structures__c": contactInputField.Structures,
            "Special_Features__c": contactInputField.SpecialFeature,
            "SubAssembly": contactInputField.SubAssembly,
            "Budget_Range__c": contactInputField.BudgetRange,
            "Delivery_Timeline__c": contactInputField.DeliveryTimeline,
        }

        console.log("contactPayload......", contactPayload);
        const response = postFormDataForAdvCathetersRFQ(JSON.stringify(contactPayload));
        console.log("response in Complex Extrusions RFQ:", response);

        response.then((res) => {
            props.handelSuccessBannerCallback(true);
            setPhone("");
            setSelectedFileName('');
            setContactInputField(initial_value);
        }).catch((err) => {
            new Error(err)
        })
    }
    return (
        <Box className="connecting-frm" sx={{ paddingBottom: "90px" }}>
            <Grid container spacing={9}>
                <Grid item xs={12} lg={6}>
                    <Box pl={{ xs: '20px', lg: '100px', xl: '176px' }} pr={{ xs: '20px', md: '0' }} pt={7} pb={4}>
                        <Typography variant="h1" fontSize='36px' color='#4A4948' fontWeight='500'
                            className='content-heading'>
                            {/* {data[0].title.replace("&#039;", "'")} */}
                        </Typography>
                        <Typography fontSize='25px' color='#131313'>
                            {/* {data[0].field_description.replace("&#039;", "'")} */}
                        </Typography>

                        <Typography fontSize='18px' color='#131313'>
                            {/* {data[0].field_description2.replace("&#039;", "'")} */}
                        </Typography>
                        <Box className='contact-details' mt={9}>
                            <Typography fontSize='18px' variant='h5' color='#29A7DF' mb={3}>
                                Biomerics Corporate Headquarters
                            </Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 888.874.7787"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 888.874.7787" underline="none" color="#4A4948">+1 888-874-7787</Link></Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 801.355.2705"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 801-355-2705" underline="none" color="#4A4948">+1 801-355-2705</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href='mailto:info@biomerics.com' target="_blank" rel="noreferrer"><img src={iconEmail} alt="" /></a>
                                <Link href="mailto:info@biomerics.com" target="_blank" color="#4A4948">info@biomerics.com</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="https://g.page/biomerics?share" target="_blank" rel="noreferrer">
                                    <img src={iconPin} alt="" />
                                </a>
                                6030 W. Harold Gatty Dr., Salt Lake City, UT 84116, USA</Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="/Locations"><img src={iconPin} alt="" /></a>
                                <Link href="/Locations" color='#29A7DF'>View other locations</Link></Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Box pr={{ xs: '20px', lg: '60px', xl: '140px' }} pl={{ xs: '20px', lg: '65px' }} py={7} pb={5} className='contact-frm'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='First Name*' name='firstName' onChange={handelInputChange} value={contactInputField.firstName} />
                                    {error.firstName.length > 0 ? <FormHelperText>{error.firstName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Last Name*' name='lastName' onChange={handelInputChange} value={contactInputField.lastName} />
                                    {error.lastName.length > 0 ? <FormHelperText>{error.lastName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Company*' name='company' onChange={handelInputChange} value={contactInputField.company} />
                                    {error.company.length > 0 ? <FormHelperText>{error.company}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Email*' name='email' onChange={handelInputChange} value={contactInputField.email} />
                                    {error.email.length > 0 ? <FormHelperText>{error.email}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} container alignItems="center">
                                <FormControl>
                                    <Typography fontSize='18px' color='#131313' fontWeight='500'>To discuss this project further, will a formal NDA need to be put into place?
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="application"
                                            value={selectedApplication}
                                            onChange={(e) => setSelectedApplication(e.target.value)}>
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Typography>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Type of Catheter/ Device
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.DeviceType}
                                        onChange={handelInputChange}
                                        name="DeviceType"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.DeviceType !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Type of Catheter/ Device</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {deviceTypeApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.DeviceType.length > 0 && <FormHelperText>{error.DeviceType}</FormHelperText>}
                                </Grid>
                            </Grid>

                            {/* Steerable Mechanism */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Steerable Mechanism
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.StreeableMechanism}
                                        onChange={handelInputChange}
                                        name="StreeableMechanism"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.StreeableMechanism !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Steerable Mechanism</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {streeableMechanismApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.StreeableMechanism.length > 0 && <FormHelperText>{error.StreeableMechanism}</FormHelperText>}
                                </Grid>
                            </Grid>

                            {/* Material Requirement */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Material Requirements
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.MaterialRequirement}
                                        onChange={handelInputChange}
                                        name="MaterialRequirement"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.MaterialRequirement !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Material Requirements</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {materialRequirementApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.MaterialRequirement.length > 0 && <FormHelperText>{error.MaterialRequirement}</FormHelperText>}
                                </Grid>
                            </Grid>

                            {/* Device Dimensions Tolerances */}
                            <Grid item xs={12} container alignItems="center" spacing={2}>
                                {/* Label on the left */}
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal', // Allow wrapping for multi-line text
                                        }}
                                    >
                                        Device Dimensions Tolerances
                                    </InputLabel>
                                </Grid>

                                {/* Input field on the right */}
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Device Dimensions"
                                            name="DeviceDimensions"
                                            onChange={handelInputChange}
                                            value={contactInputField.DeviceDimensions}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Quantities Need */}
                            <Grid item xs={12} container alignItems="center" spacing={2}>
                                {/* Label on the left */}
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal', // Allow wrapping for multi-line text
                                        }}
                                    >
                                        Quantities Needed
                                    </InputLabel>
                                </Grid>

                                {/* Input field on the right */}
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Quantities Need"
                                            name="QuantitiesNeed"
                                            onChange={handelInputChange}
                                            value={contactInputField.QuantitiesNeed}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Structures */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Structures
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.Structures}
                                        onChange={handelInputChange}
                                        name="Structures"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.Structures !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Structures</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {structuresApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.Structures.length > 0 && <FormHelperText>{error.Structures}</FormHelperText>}
                                </Grid>
                            </Grid>

                            {/* Special Features */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Special Features
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.SpecialFeature}
                                        onChange={handelInputChange}
                                        name="SpecialFeature"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.SpecialFeature !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Special Features</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {specialFeatureApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.SpecialFeature.length > 0 && <FormHelperText>{error.SpecialFeature}</FormHelperText>}
                                </Grid>
                            </Grid>

                            {/* Budget Range */}
                            <Grid item xs={12} container alignItems="center" spacing={2}>
                                {/* Label on the left */}
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal', // Allow wrapping for multi-line text
                                        }}
                                    >
                                        Budget Range
                                    </InputLabel>
                                </Grid>

                                {/* Input field on the right */}
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Budget Range"
                                            name="BudgetRange"
                                            onChange={handelInputChange}
                                            value={contactInputField.BudgetRange}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                            {/* Delivery Timeline*/}
                            <Grid item xs={12} container alignItems="center" spacing={2}>
                                {/* Label on the left */}
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal', // Allow wrapping for multi-line text
                                        }}
                                    >
                                        Delivery Timeline
                                    </InputLabel>
                                </Grid>

                                {/* Input field on the right */}
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Delivery Timeline"
                                            name="DeliveryTimeline"
                                            onChange={handelInputChange}
                                            value={contactInputField.DeliveryTimeline}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth sx={{ mb: 0, mt: 2 }}>
                                    <InputLabel
                                        htmlFor="bootstrap-input"
                                        shrink={true}
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                            position: 'relative',
                                            bottom: 0,
                                            marginBottom: '0 !important',
                                        }}
                                    >
                                        Are there any other specific requirements for this device or sub-assembly?
                                    </InputLabel>
                                    <BootstrapInput
                                        multiline={true}
                                        placeholder='Sub Assembly'
                                        name='SubAssembly'
                                        onChange={handelInputChange}
                                        value={contactInputField.SubAssembly}
                                        sx={{
                                            margin: '0 !important',
                                            paddingTop: '10px !important',
                                            paddingBottom: '0 !important',
                                        }}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ px: '24px' }}
                                        onClick={handelSubmit}
                                        disabled={
                                            !(contactInputField.firstName &&
                                                contactInputField.lastName &&
                                                contactInputField.company &&
                                                contactInputField.email)
                                        }
                                    >
                                        SUBMIT
                                    </Button>
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default AdvRFQInfusionSection;
