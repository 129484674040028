import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MIMRFQFooterSection from "./sections/MetalInjectionMoldingRFQ/RFQFooterSection";
import MIMRFQHeadSection from "./sections/MetalInjectionMoldingRFQ/RFQHeadSection";
import MIMRFQInfusionSection from "./sections/MetalInjectionMoldingRFQ/RFQInfusionSection";
import { MetalInjectionMoldingRFQResponse } from "../Service/MetalInjectionMolding";
import { METAL_INJECTION_MOLDING_RFQ_PAGE_URL } from "../common/APi/apiurl";
import { formatMIMRFQAddress, formatMIMRFQHeader, formatMIMRFQInfusion, formatMIMRFQThankYou } from "../common/utils/utils";

const MetalInjectionMoldingRFQ = () => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [LeadHeaderDetail, setLeadHeaderData] = useState<null | any>(null);
    const [LeadInfusionDetail, setLeadInfusionData] = useState<null | any>(null);
    const [LeadData, setLeadData] = useState<null | any>(null);
    const [LeadAddressDetail, setLeadAddressData] = useState<null | any>(null);
    const [LeadGenData, setLeadGenData] = useState<null | any>(null);
    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)


    const handelSuccessBanner = (flag: boolean) => {
        setShowSuccessBanner(flag)
    }
    const fetchResponse = async () => {
        const response = await MetalInjectionMoldingRFQResponse(METAL_INJECTION_MOLDING_RFQ_PAGE_URL);
        setPageContent(response)
        setLoading(false);
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let LeadHeaderData;
        let LeadInfusionData;
        let LeadData;
        let LeadAddressData;
        let LeadGenData;
        if (pageContent) {
            LeadHeaderData = formatMIMRFQHeader(pageContent);
            LeadInfusionData = formatMIMRFQInfusion(pageContent);
            LeadAddressData = formatMIMRFQAddress(pageContent);
            LeadGenData = formatMIMRFQThankYou(pageContent);
            // LeadGenData = formatContactUs(pageContent);
            setLeadInfusionData(LeadInfusionData);
            setLeadHeaderData(LeadHeaderData);
            setLeadData(LeadData);
            setLeadAddressData(LeadAddressData);
            setLeadGenData(LeadGenData);
        }
    }
    useEffect(() => {
        fetchResponse();
    }, []);
    useEffect(() => {
        formatData();
    }, [pageContent])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <MIMRFQHeadSection data={LeadHeaderDetail} />
                    <MIMRFQInfusionSection data={LeadInfusionDetail} handelSuccessBannerCallback={handelSuccessBanner} />
                    <MIMRFQFooterSection data={LeadGenData} showBanner={showSuccessBanner} />
                </React.Fragment >
            }
        </>
    );
}
export default MetalInjectionMoldingRFQ;