import axios from "axios";
import Api from "../../common/APi/api";

export const AdvancedCathetersSteerablesRFQResponse = async (url: string) => {
    const response = await Api.get(url);
    return response.data;
}

export const postFormDataForAdvCathetersRFQ = async (payloadBody: any) => {
    const res = await axios.post('https://wqkf427lkl.execute-api.us-east-1.amazonaws.com/dev/v1/bio/advancedCatheters',
        payloadBody, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    });
    return res.data;
}

export const fetchDeviceType = async () => {
    const response = await axios.get('https://wqkf427lkl.execute-api.us-east-1.amazonaws.com/dev/v1/bio/catheterDevice');
    return response.data;
}
export const fetchStreeableMechanism = async () => {
    const response = await axios.get('https://wqkf427lkl.execute-api.us-east-1.amazonaws.com/dev/v1/bio/steerableMechanism');
    return response.data;
}
export const fetchStructures = async () => {
    const response = await axios.get('https://wqkf427lkl.execute-api.us-east-1.amazonaws.com/dev/v1/bio/structure');
    return response.data;
}
export const fetchMaterialRequirement = async () => {
    const response = await axios.get('https://wqkf427lkl.execute-api.us-east-1.amazonaws.com/dev/v1/bio/materialRequirements');
    return response.data;
}
export const fetchSpecialFeature = async () => {
    const response = await axios.get('https://wqkf427lkl.execute-api.us-east-1.amazonaws.com/dev/v1/bio/specialFeatures');
    return response.data;
}