export const HOME_PAGE_CONTENT_URL = "/bio/v1/biomerics/homepage?_format=json";
export const HOME_PAGE_POPUP = "/bio/v1/biomerics/HomePageModal?_format=json";
export const COMMON_CREATIVE_SOLUTION_CONTANT_URL = "bio/v1/biomerics/creativeSolutions?_format=json";
export const CONTACT_US_PAGE_CONTENT_URL = "/bio/v1/biomerics/contactUs?_format=json";
//level2
export const BALLOONS_PAGE_CONTENT_URL = "/bio/v1/biomerics/balloonCatheters?_format=json";
export const CLEANROOM_MOLDING_PAGE_CONTENT_URL = "/bio/v1/biomerics/cleanroomMolding?_format=json";
export const CONTENT_URL = "/bio/v1/biomerics/LaserProcessing?_format=json";
export const MICRO_CONTENT_URL = "/bio/v1/biomerics/micromachining?_format=json";
export const MICRO_OVERMOLDING_URL = "/bio/v1/biomerics/microovermolding?_format=json";
export const MEDICAL_POLYMER_URL = "/bio/v1/biomerics/PerformanceMaterials?_format=json";
export const METALS_CONTENT_URL = "/bio/v1/biomerics/metals?_format=json";
export const ADVANCED_CATHETERS_CONTENT_URL = "/bio/v1/biomerics/advancedCathetersSteerables?_format=json";
export const MANUFACTURING_CONTENT_URL = "/bio/v1/biomerics/manufacturinglinetransfers?_format=json";
//level1
export const DESGIN_SERVICES_PAGE_CONTENT_URL = "/bio/v1/biomerics/designservices?_format=json";
export const MATERIAL_AND_COMPOUNDING_PAGE_CONTENT_URL = "/bio/v1/biomerics/materials?_format=json";
export const FINISHED_DEVICES_PAGE_CONTENT_URL = "/bio/v1/biomerics/finished_devices?_format=json";
export const ENGG_COMPONENTS_PAGE_CONTENT_URL = "/bio/v1/biomerics/engineeredComponents?_format=json";
export const PHARMACEUTICAL_PACKAGING_PAGE_CONTENT_URL = "/bio/v1/biomerics/PharmaceuticalPackaging?_format=json";
//about us 
export const ABOUT_US_PAGE_CONTENT_URL = "/bio/v1/biomerics/aboutus?_format=json";
export const WHO_WE_ARE_PAGE_CONTENT_URL = "/bio/v1/biomerics/whoweare?_format=json";
export const CAREERS_PAGE_CONTENT_URL = "/bio/v1/biomerics/careers?_format=json";
//leadership
export const LEADERSHIP_PAGE_CONTENT_URL = "/bio/v1/biomerics/leadership?_format=json";
export const MARKETS_PAGE_CONTENT_URL = "/bio/v1/biomerics/markets?_format=json";
export const QUALITY_PAGE_CONTENT_URL = "/bio/v1/biomerics/quality?_format=json";
export const CERTIFICATIONS_PAGE_CONTENT_URL = "/bio/v1/biomerics/certifications?_format=json";
export const LOCATIONS_PAGE_CONTENT_URL = "/bio/v1/biomerics/locations?_format=json";
export const IMAGEGUIDEDINTERVENSION_PAGE_CONTENT_URL = "/bio/v1/biomerics/imageguidedintervention?_format=json";
//blog(news & resourec)
export const BLOG = "/bio/v1/biomerics/blogs?_format=json&is_pinned=false";
export const BLOG_PINNED = "/bio/v1/biomerics/blogs?_format=json&is_pinned=true";
export const BLOG_HEADER = "/bio/v1/biomerics/blogHeader?_format=json";
//RFQ
export const RFQ = "/bio/v1/biomerics/rfqForm?_format=json";
//PRIVACY POLICY
export const PRIVACY_POLICY = "/bio/v1/biomerics/privacyPolicy?_format=json";
//three pages
export const COMPONNET_DEVELOPEMENT = "/bio/v1/biomerics/componentdevelopment?_format=json";
export const NEW_PRODUCT_DEVELOPEMNT = "/bio/v1/biomerics/newproductdevelopment?_format=json";
export const TECH_TRANSFER = "/bio/v1/biomerics/techtransfer?_format=json";
export const TINTUBE_MANUFACTURING = "/bio/v1/biomerics/tintubemanufacturing?_format=json";
export const MDI_COATING = "/bio/v1/biomerics/MDICoating?_format=json";

//Our Board

export const COMPLEX_EXTRUSIONSS_PAGE_URL = "/bio/v1/biomerics/ComplexExtrusionss?_format=json";
export const COMPLEX_EXTRUSION_PAGE_CONTENT_URL = "/bio/v1/biomerics/complexExtrusions?_format=json";
export const COMPLEX_EXTRUSIONS_RFQ_PAGE_URL = "/bio/v1/biomerics/ComplexExtrusionsRFQ?_format=json";
export const OUR_BOARD_PAGE_CONTENT_URL = "/bio/v1/biomerics/OurBoard?_format=json";

//level4 pages

//export const MICRO_METAL_FINISHED_ASSEMBLY_URL="/bio/v1/biomerics/OurBoard?_format=json";
export const MICRO_METAL_FINISHED_ASSEMBLY_URL = "/bio/v1/biomerics/MicroMetalsFinishedAssembly?_format=json";
export const METAL_FABRICATION_URL = "/bio/v1/biomerics/MetalFabrication?_format=json";
export const METAL_FINISHING_URL = "/bio/v1/biomerics/MetalFinishing?_format=json";
export const GUIDEWIRES_URL = "/bio/v1/biomerics/Guidewires?_format=json";
export const METAL_INJECTION_MOLDING_URL = "/bio/v1/biomerics/MetalInjectionMolding?_format=json";


export const PERFORMANCE_MATERIALS_RFQ_PAGE_CONTENT_URL = "/bio/v1/biomerics/PerformanceMaterialsRFQ?_format=json";

//Automation page
export const AUTOMATION_PAGE_CONTENT_URL = "/bio/v1/biomerics/automation?_format=json";

// Dummy
export const NEW_AUTO_DEV_PAGE_CONTENT_URL = "/bio/v1/biomerics/newautodevelopment?_format=json";


// Terms and Conditions

export const TERMS_AND_CONDITIONS_URL = "/bio/v1/biomerics/purchaseOrderTermsAndConditions?_format=json";

// MIM RFQ
export const METAL_INJECTION_MOLDING_RFQ_PAGE_URL = "/bio/v1/biomerics/MetalInjectionMoldingRFQ?_format=json";
export const ADVANCED_CATHETERS_CONTENT_RFQ_URL = "/bio/v1/biomerics/AdvancedCatheters%26SteerablesRFQ?_format=json";
