import React, { FC, useState, useCallback, useEffect, useRef } from 'react'
import { Box, Container, Grid, MenuItem, Select, Typography } from '@mui/material'
import e2eImage from '../../../assets/images/img4.jpg';
import Link from '@mui/material/Link';
import iconCall from '../../../assets/images/icons/call.svg';
import iconPin from '../../../assets/images/icons/pin.svg';
import iconEmail from '../../../assets/images/icons/email.svg';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ContactAddress from '../ContactSection/ContactAddress';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { postUserFormDataForContactPage, fetchAreaOfInterestDropdownValue } from '../../../Service/ContactUs';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import MuiPhoneNumber from 'material-ui-phone-number';
import { postRfqFormData } from '../../../Service/Rfq';
import { GOOGLE_CAPTCHA_SITE_KEY, PROD_GOOGLE_CAPTCHA_SITE_KEY, DEV_GOOGLE_CAPTCHA_SITE_KEY } from '../../../common/Constants';
// console.log(ContactAddress, "lll");
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];
interface userContactInformation {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    tellUs_field: string;
    companyName: string;
    areaOfInterest: string;
    ReCAPTCHA: string;
    sme: string;
}


export interface ErrorMessage {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    companyName: string;
    areaOfInterest: string;
    ReCAPTCHA: string;
    tellUs_field: string;
}

export interface Phonenum {
    phone: string;
}
const phone_value: Phonenum = {
    phone: ''
};


const initial_value: userContactInformation = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    tellUs_field: '',
    areaOfInterest: "",
    ReCAPTCHA: '',
    sme: "No"
}

interface ApiResponseDropdown {
    body: string[],
    loading: boolean
}

const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const ContactInfusion = (props: any) => {
    const { data } = props;


    const [contactInputField, setContactFormField] = useState<userContactInformation>(initial_value);
    const [contactNumberField, setContactNumberField] = useState<Phonenum>(phone_value);
    const [loading, setLoading] = useState<boolean>(true);
    const [phone, setPhone] = useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [areaOfInterestApiResponse, setAreaOfInterestApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    })

    //const [disable, setDisable]=useState<boolean>(true);
    //const [area]
    const [error, setError] = useState<ErrorMessage>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        companyName: '',
        areaOfInterest: '',
        email: '',
        ReCAPTCHA: '',
        tellUs_field: '',
    });


    const [sme, setSme] = useState<string>("No")
    const clearInputField = () => {
        setContactFormField(initial_value);
    }

    useEffect(() => {

        window.scroll(0, 0)
        const response = fetchAreaOfInterestDropdownValue();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setAreaOfInterestApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            console.log('e', err)
        })
    }, [])




    function handlePhoneChange(e: any) {
        if (e) {
            const name = 'phone';
            let value = e;
            setPhone(value);
            setContactNumberField({ ...contactNumberField, [name]: e })
        } else if (e.length < 2) {
            setErrorMessage("Example error message!")
            setPhone(e);
        }
    }
    const clearPhoneField = () => {

        setContactNumberField(phone_value);
    }


    const handleCheckBox = (event: any) => {
        const { checked } = event.target;
        setSme(checked ? "Yes" : "No");
    };

    const handelInputChange = (e: any) => {
        const { name, value, id } = e.target;

        const re = /^\S*$/;
        let errors: ErrorMessage = error;
        setContactFormField({ ...contactInputField, [name]: value, [id]: value })
        if (name === 'firstName') {

            if (!value) {
                setError({ ...error, [name]: "Please add valid name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid name" })
                //errors["firstName"] = "Please enter valid first name.";
            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (name === 'lastName') {
            if (!value) {
                setError({ ...error, [name]: "Please add valid Last name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid last name" })
                //errors["firstName"] = "Please enter valid first name.";
            }
            //else {
            //     setError({...error, [name]:""})
            //    }
        } else if (name === 'email') {
            if (!value) {
                //isValid = false;
                setError({ ...error, [name]: "Please enter your email Address." })
                //errors["email"] = ";
            } else {
                setError({ ...error, [name]: "Please fill email field" })
            }

            if (typeof value !== "undefined") {
                if (!value) {
                    // isValid = false;
                    // errors["email"] = "Please enter valid email address.";
                    setError({ ...error, [name]: "Please enter valid email address." })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        } else if (name === 'phoneNumber') {
            if (!value) {
                // isValid = false;
                //errors["phoneNumber"] = "Please enter your phoneNumber";
                setError({ ...error, [name]: "Please enter valid phoneNumber" })
            } else {
                setError({ ...error, [name]: "" })
            }


            if (typeof value !== "undefined") {
                //   const re = /^\S*$/;
                var phoneNum = value.replace(/[^\d]/g, '');
                if (phoneNum.length < 6 || phoneNum.length < 10) {
                    //isValid = false;
                    //errors["phoneNumber"] = "Please enter valid phone number.";
                    setError({ ...error, [name]: "Please enter your valid phoneNumber" })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        } else if (name === "areaOfInterest") {
            if (!value) {
                // errors["areaOfInterest"]="Please select any one";
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (verified) {
            if (!verified) {
                // errors["areaOfInterest"]="Please select any one";
                setError({ ...error, [id]: "Please select captcha" })
            } else {
                setError({ ...error, [id]: "" })
            }
        } else if (name === "tellUs_field") {
            if (!value) {
                setError({ ...error, [name]: "Please tell us more so we can help you better" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }


    }


    const [verified, setVerified] = useState(false);

    const myRef = useRef(null);
    const scrollToRef = (ref: any) => window.scrollTo(0, 1200)
    //const executeScroll = () => scrollToRef(myRef);
    const ScrollDemo = () => {
        const myRef = useRef(null)
        const executeScroll = () => scrollToRef(myRef)
    }

    function handelCaptcha(value: any) {
        setVerified(value);

    }


    const handelSubmit = (e: React.SyntheticEvent) => {
        // window.scrollTo(0,1200);
        scrollToRef(myRef);
        let screen_width = window.screen.width;
        if (screen_width < 768) {
            window.scrollTo(0, 2000);
        }

        e.preventDefault();
        // const contactPayload = {
        //     "firstname": contactInputField.firstName,
        //     "lastname": contactInputField.lastName,
        //     "company": contactInputField.companyName,
        //     "email": contactInputField.email,
        //     "phone": phone,
        //     // "Product_Category__c": contactInputField.areaOfInterest,
        //     "Process_Class__c": contactInputField.areaOfInterest,     // changed from Product_Category__c to Process_Class__c
        //     "Commercial_Director__c": "Not Yet Assigned", //sending a dependent field for product category onto the salesforce
        //     "Marketing_Notes__c": contactInputField.tellUs_field,
        //     "Is_Lead_SME_for_this_Project__c": sme,
        //     "lead_source": "Website",
        //     // "ReCAPTCHA":contactInputField.verified
        // }

        const contactPayload = {
            "firstname": contactInputField.firstName,
            "lastname": contactInputField.lastName,
            "company": contactInputField.companyName,
            "email": contactInputField.email,
            "phone": phone,
            // "Product_Category__c": contactInputField.areaOfInterest,
            // "Process_Class_Notes__c": "",
            "Process_Class__c": contactInputField.areaOfInterest,     // changed from Product_Category__c to Process_Class__c
            "tell_us_more": contactInputField.tellUs_field,
            "sme": sme,
            "lead_source": "Website",
            // "ReCAPTCHA":contactInputField.verified
        }

        let clearPhone: '';
        // console.log("contactPayload:", contactPayload);
        const response = postUserFormDataForContactPage(JSON.stringify(contactPayload));
        response.then((res) => {

            //alert("Submitted!!")
            props.handelSuccessBannerCallback(true);
            clearPhoneField();
            clearInputField();
            setPhone("");
            setSme('');
            //setVerified(false);
        }).catch((err) => {
            alert('Something went wrong');
        })
    }
    return (
        <Box className="connecting-frm">
            <Grid container spacing={9}>
                <Grid item xs={12} lg={6}>
                    <Box pl={{ xs: '20px', lg: '100px', xl: '330px' }} pr={{ xs: '20px', md: '0' }} pt={7} pb={4}>
                        <Typography variant="h1" fontSize='36px' color='#4A4948' fontWeight='500'
                            className='content-heading'>{data[0].title.replace("&#039;", "'")}</Typography>
                        <Typography fontSize='25px' color='#131313'>
                            {data[0].field_description.replace("&#039;", "'")}
                        </Typography>
                        <Box my={4}>
                            <RouterLink to="/Rfq" className='btn-blue btn-rfq'>
                                {data[0].field_button_text}
                            </RouterLink>
                        </Box>
                        <Typography fontSize='18px' color='#131313'>
                            {data[0].field_description2.replace("&#039;", "'")}
                        </Typography>
                        <Box className='contact-details' mt={9}>
                            <Typography fontSize='18px' variant='h5' color='#29A7DF' mb={3}>
                                Biomerics Corporate Headquarters
                            </Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 888.874.7787"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 888.874.7787" underline="none" color="#4A4948">+1 888-874-7787</Link></Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 801.355.2705"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 801-355-2705" underline="none" color="#4A4948">+1 801-355-2705</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href='mailto:info@biomerics.com' target="_blank" rel="noreferrer"><img src={iconEmail} alt="" /></a>
                                <Link href="mailto:info@biomerics.com" target="_blank" color="#4A4948">info@biomerics.com</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="https://g.page/biomerics?share" target="_blank" rel="noreferrer">
                                    <img src={iconPin} alt="" />
                                </a>
                                6030 W Harold Gatty Dr., Salt Lake City, UT 84116</Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="/Locations"><img src={iconPin} alt="" /></a>
                                <Link href="/Locations" color='#29A7DF'>View other locations</Link></Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Box pr={{ xs: '20px', lg: '60px', xl: '220px' }} pl={{ xs: '20px', lg: '65px' }} py={7} pb={5} className='contact-frm'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='First Name*' name='firstName' onChange={handelInputChange} value={contactInputField.firstName} />
                                    {error.firstName.length > 0 ? <FormHelperText>{error.firstName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Last Name*' name='lastName' onChange={handelInputChange} value={contactInputField.lastName} />
                                    {error.lastName.length > 0 ? <FormHelperText>{error.lastName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Company*' name='companyName' onChange={handelInputChange} value={contactInputField.companyName} />
                                    {error.companyName.length > 0 ? <FormHelperText>{error.companyName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Email*' name='email' onChange={handelInputChange} value={contactInputField.email} />
                                    {error.email.length > 0 ? <FormHelperText>{error.email}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="mobile-number">
                                {/* <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Phone number*' type="number" name='phoneNumber' onChange={handelInputChange} 
                                    value={contactInputField.phoneNumber} />
                                    {error.phoneNumber.length > 0 ? <FormHelperText>{error.phoneNumber}</FormHelperText> : ''} 
                                 </FormControl> */}

                                <MuiPhoneNumber
                                    disableCountryCode={false}
                                    defaultCountry={"us"}
                                    name="phone"
                                    variant="standard" className='w-100' sx={{ mb: '6px' }}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                //onChange={(e) => setPhone(e)}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Area of interest*
                                    </InputLabel>
                                    <Select sx={{
                                        width: '100%', borderRadius: '0', backgroundColor: '#ffffff',
                                        fontSize: '16px', boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)', border: 'none'
                                    }}
                                        className='w-100 frm-select '
                                        value={contactInputField.areaOfInterest}
                                        onChange={handelInputChange}
                                        name='areaOfInterest'
                                        // label="General capabilities" 
                                        displayEmpty
                                        renderValue={contactInputField.areaOfInterest !== "" ? undefined : () => <Box className='select-placeholder'>General capabilities</Box>}
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >

                                        {areaOfInterestApiResponse.body?.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}

                                            >
                                                {name}

                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <FormHelperText>Required</FormHelperText> */}
                                    {error.areaOfInterest.length > 0 ? <FormHelperText>{error.areaOfInterest}</FormHelperText> : ''}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Please tell us more so we can best follow up*
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='Hello...' rows={5} name='tellUs_field' onChange={handelInputChange}
                                        value={contactInputField.tellUs_field} />
                                    {error.tellUs_field.length > 0 ? <FormHelperText>{error.tellUs_field}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className='marketing-email-box'>
                                    <FormControlLabel
                                        control={<Checkbox checked={sme === 'Yes'} />} // Set checked prop based on sme value
                                        label="Sign me up for marketing emails"
                                        onChange={handleCheckBox}
                                    />
                                </Box>

                                {/* <Box className='marketing-email-box'>
                                    <FormControlLabel control={<Checkbox />} label="Sign me up for marketing emails"  value='Yes'    name="sme" onChange={handleCheckBox} />
                                </Box> */}
                            </Grid>
                            <Grid item xs={12}>
                                <ReCAPTCHA
                                    id='ReCAPTCHA'
                                    //sitekey='6LfccVMgAAAAAJLczVdKyHOwbC0xrjXQx5RMEhKH'//test key
                                    //sitekey='6LdbC_MgAAAAAB-qciWOOokkQppNMXFt6S-3b5Cd' //production site kry
                                    sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                                    onChange={handelCaptcha}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <Button variant="contained" className='' sx={{ px: '24px' }} onClick={handelSubmit}
                                        disabled={
                                            !contactInputField.firstName ||
                                            !contactInputField.lastName ||
                                            !contactInputField.areaOfInterest ||  // Ensures this field is filled
                                            !contactInputField.companyName ||
                                            !contactInputField.email ||
                                            !contactInputField.tellUs_field
                                        }
                                    >SUBMIT</Button>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default ContactInfusion;
