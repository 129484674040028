import axios from "axios";
import Api from "../../common/APi/api";

export const MetalInjectionMoldingRFQResponse = async (url: string) => {
    const response = await Api.get(url);
    return response.data;
}

export const postFormDataForMIMRFQ = async (payloadBody: any) => {
    const res = await axios.post('https://s53d46z37b.execute-api.us-east-1.amazonaws.com/dev/v1/bio/metalInjectionModelingRfq',
        payloadBody, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    });

    return res.data;
}

export const fetchProjectTimeFrameDropdownValue = async () => {
    const response = await axios.get('https://s53d46z37b.execute-api.us-east-1.amazonaws.com/dev/v1/bio/TimeframeForProject');
    return response.data;
}

export const fetchMaterialSelectionDropdownValue = async () => {
    const response = await axios.get('https://s53d46z37b.execute-api.us-east-1.amazonaws.com/dev/v1/bio/materialSelection');
    return response.data;
}