import React, { useState, useEffect, useRef } from 'react'
import { Box, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material'
import Link from '@mui/material/Link';
import iconCall from '../../../assets/images/icons/call.svg';
import iconPin from '../../../assets/images/icons/pin.svg';
import iconEmail from '../../../assets/images/icons/email.svg';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import MuiPhoneNumber from 'material-ui-phone-number';
import { fetchMaterialSelectionDropdownValue, fetchProjectTimeFrameDropdownValue, postFormDataForMIMRFQ } from '../../../Service/MetalInjectionMolding';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

interface userContactInformation {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    phoneNumber: string;
    projectNDA: string;
    MaterialSelection: string;
    PartDimensions: string;
    EstimateAnnualUsage: string;
    ProjectTimeFrame: string;
    ProjectDetails: string;
    grams: string;
}

export interface ErrorMessage {
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    projectNDA: string;
    MaterialSelection: string;
    PartDimensions: string;
    EstimateAnnualUsage: string;
    ProjectTimeFrame: string;
    ProjectDetails: string;
}

const initial_value: userContactInformation = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phoneNumber: '',
    projectNDA: '',
    MaterialSelection: '',
    PartDimensions: '',
    EstimateAnnualUsage: '',
    ProjectTimeFrame: '',
    ProjectDetails: '',
    grams: '',
}

interface ApiResponseDropdown {
    body: string[],
    loading: boolean
}


export interface Phonenum {
    phone: string;
}
const phone_value: Phonenum = {
    phone: ''
};

const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;


const MIMRFQInfusionSection = (props: any) => {
    const { data } = props;
    const [contactInputField, setContactInputField] = useState<userContactInformation>(initial_value);
    const [selectedProjects, setSelectedProjects] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selectedFileName, setSelectedFileName] = useState('');
    const [contactNumberField, setContactNumberField] = useState<Phonenum>(phone_value);
    const [phone, setPhone] = useState("");
    const myRef = useRef(null);

    const scrollToRef = (ref: any) => window.scrollTo(0, 1200);

    const [ProjectTimeFrameApiResponse, setProjectTimeFrameApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    })

    const [MaterialSelectionApiResponse, setMaterialSelectionApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    });

    const [error, setError] = useState<ErrorMessage>({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        projectNDA: '',
        MaterialSelection: '',
        PartDimensions: '',
        EstimateAnnualUsage: '',
        ProjectTimeFrame: '',
        ProjectDetails: '',
    });

    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchProjectTimeFrameDropdownValue();
        response.then((res: any): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setProjectTimeFrameApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err: any) => {
            throw err
        })
    }, [])


    useEffect(() => {
        window.scroll(0, 0)
        const response = fetchMaterialSelectionDropdownValue();
        response.then((res: any): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setMaterialSelectionApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err: any) => {
            throw err
        })
    }, [])


    const handelInputChange = (e: any) => {
        const { name, value, id } = e.target;

        const re = /^\S*$/;
        let errors: ErrorMessage = error;
        setContactInputField({ ...contactInputField, [name]: value, [id]: value })
        if (name === 'firstName') {

            if (!value) {
                setError({ ...error, [name]: "Please add valid name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid name" })

            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (name === 'lastName') {
            if (!value) {
                setError({ ...error, [name]: "Please add valid Last name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid last name" })
            }

        } else if (name === 'email') {
            if (!value) {

                setError({ ...error, [name]: "Please enter your email Address." })

            } else {
                setError({ ...error, [name]: "Please fill email field" })
            }

            if (typeof value !== "undefined") {
                if (!value) {
                    // isValid = false;
                    // errors["email"] = "Please enter valid email address.";
                    setError({ ...error, [name]: "Please enter valid email address." })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        }

        else if (name === "ProjectTimeFrame") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }

        else if (name === "MaterialSelection") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
    }

    const handelSubmit = (e: React.SyntheticEvent) => {
        // window.scrollTo(0,1200);
        scrollToRef(myRef);
        let screen_width = window.screen.width;
        if (screen_width < 768) {
            window.scrollTo(0, 2000);
        }

        e.preventDefault();

        const contactPayload = {
            "firstname": contactInputField.firstName,
            "lastname": contactInputField.lastName,
            "company": contactInputField.company,
            "email": contactInputField.email,
            "projectNDA": selectedProjects,
            "Material_Selection__c": contactInputField.MaterialSelection,
            "PartDimensions": contactInputField.PartDimensions,
            "EstimateAnnualUsage": contactInputField.EstimateAnnualUsage,
            "Timeframe_for_Project__c": contactInputField.ProjectTimeFrame,
            "ProjectDetails": contactInputField.ProjectDetails,
            "grams": contactInputField.grams,
        }

        // console.log("contactPayload:", contactPayload);
        const response = postFormDataForMIMRFQ(JSON.stringify(contactPayload));

        response.then((res: any) => {
            props.handelSuccessBannerCallback(true);
            setPhone("");
            setSelectedFileName('');
            setSelectedProjects('');
            setContactInputField(initial_value);
        }).catch((err: any) => {
            new Error(err)
        })
    }
    function handlePhoneChange(e: any) {
        if (e) {
            const name = 'phone';
            let value = e;
            setPhone(value);
            setContactNumberField({ ...contactNumberField, [name]: e })
        } else if (e.length < 2) {
            setErrorMessage("Example error message!")
            setPhone(e);
        }
    }
    const clearPhoneField = () => {
        setContactNumberField(phone_value);
    }
    return (
        <Box className="connecting-frm" sx={{ paddingBottom: "90px" }}>
            <Grid container spacing={9}>
                <Grid item xs={12} lg={6}>
                    <Box pl={{ xs: '20px', lg: '100px', xl: '176px' }} pr={{ xs: '20px', md: '0' }} pt={7} pb={4}>
                        <Typography variant="h1" fontSize='36px' color='#4A4948' fontWeight='500'
                            className='content-heading'>
                            {data[0].title.replace("&#039;", "'")}
                        </Typography>
                        <Typography fontSize='25px' color='#131313'>
                            {data[0].field_description.replace("&#039;", "'")}
                        </Typography>

                        <Typography fontSize='18px' color='#131313'>
                            {data[0].field_description2.replace("&#039;", "'")}
                        </Typography>
                        <Box className='contact-details' mt={9}>
                            <Typography fontSize='18px' variant='h5' color='#29A7DF' mb={3}>
                                Biomerics Corporate Headquarters
                            </Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 888.874.7787"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 888.874.7787" underline="none" color="#4A4948">+1 888-874-7787</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href='mailto:info@biomerics.com' target="_blank" rel="noreferrer"><img src={iconEmail} alt="" /></a>
                                <Link href="mailto:info@biomerics.com" target="_blank" color="#4A4948">info@biomerics.com</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="https://g.page/biomerics?share" target="_blank" rel="noreferrer">
                                    <img src={iconPin} alt="" />
                                </a>
                                6030 W. Harold Gatty Dr., Salt Lake City, UT 84116, USA</Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="/Locations"><img src={iconPin} alt="" /></a>
                                <Link href="/Locations" color='#29A7DF'>View other locations</Link></Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Box pr={{ xs: '20px', lg: '60px', xl: '140px' }} pl={{ xs: '20px', lg: '65px' }} py={7} pb={5} className='contact-frm'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='First Name*' name='firstName' onChange={handelInputChange} value={contactInputField.firstName} />
                                    {error.firstName.length > 0 ? <FormHelperText>{error.firstName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Last Name*' name='lastName' onChange={handelInputChange} value={contactInputField.lastName} />
                                    {error.lastName.length > 0 ? <FormHelperText>{error.lastName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Company*' name='company' onChange={handelInputChange} value={contactInputField.company} />
                                    {error.company.length > 0 ? <FormHelperText>{error.company}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Email*' name='email' onChange={handelInputChange} value={contactInputField.email} />
                                    {error.email.length > 0 ? <FormHelperText>{error.email}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} className="mobile-number">
                                <MuiPhoneNumber
                                    disableCountryCode={false}
                                    defaultCountry={"us"}
                                    name="phone"
                                    variant="standard" className='w-100' sx={{ mb: '6px' }}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </Grid>

                            <Grid item xs={12} container alignItems="center">
                                <FormControl>
                                    <Typography fontSize='18px' color='#131313'>To discuss this project further, will a formal NDA need to be put into place?
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="projectNDA"
                                            value={selectedProjects}
                                            onChange={(e) => setSelectedProjects(e.target.value)}>
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Typography>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Material Selection*
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.MaterialSelection}
                                        onChange={handelInputChange}
                                        name="MaterialSelection"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.MaterialSelection !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Iron-based alloys</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {MaterialSelectionApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.MaterialSelection.length > 0 && <FormHelperText>{error.MaterialSelection}</FormHelperText>}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container spacing={2} alignItems="flex-start">
                                {/* Part Dimensions */}
                                <Grid item xs={8} display="flex" flexDirection="column">
                                    <InputLabel
                                        htmlFor="bootstrap-input"
                                        shrink
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Part Dimensions (approx.)
                                        Length x Width x Height
                                    </InputLabel>
                                    <Box display="flex" alignItems="center">
                                        {[1, 2, 3].map((_, idx) => (
                                            <React.Fragment key={idx}>
                                                <BootstrapInput
                                                    value={
                                                        contactInputField.PartDimensions.split(" x ")[idx] || ""
                                                    }
                                                    sx={{
                                                        padding: '10px',
                                                        width: '90px',
                                                        textAlign: 'center',
                                                    }}
                                                    onChange={(e) => {
                                                        const updatedValue = e.target.value;
                                                        const dimensions = contactInputField.PartDimensions.split(" x ");
                                                        dimensions[idx] = updatedValue; // Update specific dimension
                                                        handelInputChange({
                                                            target: {
                                                                name: "PartDimensions",
                                                                value: dimensions.join(" x ").trim(), // Combine updated dimensions
                                                            },
                                                        });
                                                    }}
                                                />
                                                {idx < 2 && (
                                                    <Typography
                                                        sx={{
                                                            marginX: '4px',
                                                            fontSize: '1.2rem',
                                                        }}
                                                    >
                                                        X
                                                    </Typography>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                </Grid>

                                {/* Part Mass */}
                                <Grid item xs={4} display="flex" flexDirection="column">
                                    <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                        <InputLabel
                                            shrink
                                            sx={{
                                                color: '#000 !important',
                                                transform: 'none',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            Part Mass
                                        </InputLabel>
                                        <BootstrapInput
                                            placeholder='(Grams)'
                                            name='grams'
                                            onChange={handelInputChange}
                                            value={contactInputField.grams} />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth sx={{ mb: 0, mt: 2 }}>
                                    <InputLabel
                                        htmlFor="bootstrap-input"
                                        shrink={true}
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                            position: 'relative',
                                            bottom: 0,
                                            marginBottom: '0 !important',
                                        }}
                                    >
                                        EAU (Estimated Annual Usage)
                                    </InputLabel>
                                    <BootstrapInput
                                        multiline={true}
                                        placeholder='Estimate Annual Usage'
                                        name='EstimateAnnualUsage'
                                        onChange={handelInputChange}
                                        value={contactInputField.EstimateAnnualUsage}
                                        sx={{
                                            margin: '0 !important',
                                            paddingTop: '10px !important',
                                            paddingBottom: '0 !important',
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} container alignItems="center">
                                <InputLabel
                                    shrink={true}
                                    htmlFor="bootstrap-input"
                                    sx={{
                                        color: '#000 !important',
                                        transform: 'none',
                                        whiteSpace: 'normal',
                                        position: 'relative',
                                        bottom: 0,
                                        paddingBottom: '10px !important',
                                    }}
                                >
                                    What is the timeframe for this project?*
                                </InputLabel>
                                <Select
                                    sx={{
                                        width: '100%',
                                        borderRadius: '0',
                                        backgroundColor: '#ffffff',
                                        fontSize: '16px',
                                        boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                        border: 'none',
                                        paddingTop: '10px !important',
                                        paddingBottom: '0 !important',
                                    }}
                                    className="w-100 frm-select"
                                    value={contactInputField.ProjectTimeFrame}
                                    onChange={handelInputChange}
                                    name="ProjectTimeFrame"
                                    displayEmpty
                                    renderValue={
                                        contactInputField.ProjectTimeFrame !== ""
                                            ? undefined
                                            : () => <Box className="select-placeholder">1-6 months</Box>
                                    }
                                    input={<OutlinedInput id="bootstrap-input" />}
                                >
                                    {ProjectTimeFrameApiResponse.body?.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error.ProjectTimeFrame.length > 0 && <FormHelperText>{error.ProjectTimeFrame}</FormHelperText>}
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth sx={{ mb: 0, mt: 2 }}>
                                    <InputLabel
                                        htmlFor="bootstrap-input"
                                        shrink={true}
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                            position: 'relative',
                                            bottom: 0,
                                            marginBottom: '0 !important',
                                        }}
                                    >
                                        Please tell us more about your project. (ProjectDetails, Market, End-use, Current Manufacturing Process, Reason for evaluating MIM)
                                    </InputLabel>
                                    <BootstrapInput
                                        multiline={true}
                                        placeholder='Project Details'
                                        name='ProjectDetails'
                                        onChange={handelInputChange}
                                        value={contactInputField.ProjectDetails}
                                        sx={{
                                            margin: '0 !important',
                                            paddingTop: '10px !important',
                                            paddingBottom: '0 !important',
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ px: '24px' }}
                                        onClick={handelSubmit}
                                        disabled={
                                            !(contactInputField.firstName &&
                                                contactInputField.lastName &&
                                                contactInputField.company &&
                                                contactInputField.email)
                                        }
                                    >
                                        SUBMIT
                                    </Button>
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default MIMRFQInfusionSection;
