import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import img1 from '../../assets/images/img1.jpg';
import arrow from '../../assets/images/arrow.svg';
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
//Get Images by const variable
import fastbaloonImg from '../../../assets/images/fast-baloon.png';
import CommonList from './CommonList';
import { common } from '@mui/material/colors';
import ReactPlayer from 'react-player';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Fade from '@mui/material/Fade';
import { CONTACT_US_PAGE_CONTENT_URL } from '../../../common/APi/apiurl';
import Linkify from 'react-linkify';
import { HashLink } from 'react-router-hash-link';
import { useLocation, useNavigate } from 'react-router-dom';

const CommonRepetativeSections = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.pathname;
  const { data, banner } = props;
  const [open, setOpen] = React.useState(false);
  const onCloseModal = () => setOpen(false);
  const [shown, setShown] = React.useState('');
  const [spanishTitle, setSpanishTitle] = React.useState('');
  const [buttonText, setButtonText] = React.useState('');
  const [currentVideoUrl, setCurrentVideoUrl] = React.useState('');
  const [currentUrl, setCurrentUrl] = React.useState('');
  const [activeSection, setActiveSection] = useState('');
  const [ourValueClassName, setOurValueClassName] = useState<string>("");

  console.log("CombinedItems DATA:", data);
  console.log("CombinedItems BANNER:", banner);

  useEffect(() => {
    const lowercasePathname = location.pathname.toLowerCase();

    let targetElementId = '';

    if (lowercasePathname === '/internships') {
      targetElementId = '#section323';
    } else if (lowercasePathname === '/apprenticeships') {
      targetElementId = '#section664';
    } else if (lowercasePathname === '/superate') {
      targetElementId = '#section710';
    }

    if (targetElementId) {
      setTimeout(() => {
        const anchor = document.createElement('a');
        anchor.href = targetElementId;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, ['/WhoWeAre']);

  if (Array.isArray(banner) && banner.length > 0) {
    const combinedItems = [...data, ...banner];

    console.log("data [if] :", data);
    console.log("banner [if] :", banner);

    // Remove items with nid 225 and 226 from combinedItems
    const filteredItems = combinedItems.filter(item => item.nid !== "225" && item.nid !== "226");

    // Find items with nid 225 and 226
    const item225 = combinedItems.find(item => item.nid === "225");
    const item226 = combinedItems.find(item => item.nid === "226");

    // Sort filteredItems based on nid in ascending order
    const sortedItems = filteredItems.sort((a, b) => parseInt(a.nid) - parseInt(b.nid));

    // Insert item226 at the 0rd index (index 0)
    if (item226) {
      sortedItems.splice(0, 0, item226);
    }

    // Insert item225 immediately after item226
    if (item225) {
      const index226 = sortedItems.findIndex(item => item.nid === "226");
      sortedItems.splice(index226 + 1, 0, item225);
    }


    return (
      <>
        {/* Map over data */}
        {sortedItems.map((commonData: any, index: any) => {
          let number = index;
          let transparantImage = commonData.field_bg_image_url;

          const handleDialogOpenTwo = (e: any, videoUrl: string) => {
            if (e.target.id === commonData.nid) {
              setCurrentVideoUrl(videoUrl);
              setOpen(true);
            }
          };

          function decodeEntity(inputStr: any) {
            var textarea = document.createElement("textarea");
            textarea.innerHTML = inputStr;
            return textarea.value;
          }

          const padding_bottom = {
            paddingBottom: commonData.field_center_bottom_padding + '!important'
          };

          const padding_left = {
            paddingLeft: commonData.field_pd_image_left + '!important'
          };

          const padding_right = {
            paddingRight: commonData.field_pd_image_right + '!important'
          };

          const padding_top = {
            paddingTop: commonData.field_center_top_padding + '!important'
          };

          var list_1 = commonData.field_description2.split("\r\n");
          var field1 = commonData.field_description.replace("&gt;", ">");
          var field2 = commonData.field_description3.replace("&gt;", ">");
          var bulletsColor = { "--bullet-bg-color": commonData.field_bullets_fgcolor } as React.CSSProperties;

          const field_description = decodeEntity(field1);
          const field_description2 = decodeEntity(field2);
          const button_url = decodeEntity(commonData.field_button_url);
          const question_button_url = decodeEntity(commonData.field_question_button_1_url);

          var list_3 = commonData.field_description4.split("\r\n");
          var list_data = decodeEntity(list_3);

          const ShowlistData_1 = (list_1: any) => {
            return list_1.map((data: any, i: any) => (
              <>
                {data !== "" &&
                  <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
                    <ListItemText>{data.replace("&gt;", ">").split("amp;")}</ListItemText>
                  </ListItem>
                }
              </>
            ));
          };

          const ShowlistData_3 = (list_3: any) => {
            return list_3.map((data: any, i: any) => (
              <>
                {data !== "" &&
                  <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
                    <ListItemText>{data.replace("&gt;", ">").split("amp;")}</ListItemText>
                  </ListItem>
                }
              </>
            ));
          };

          var checkButtonRedirect = button_url.includes("https") ? button_url : button_url;

          var checkQuestionButtonRedirect = question_button_url.includes("https") ? question_button_url : question_button_url;

          const handleButtonClick = (commonData: any) => {
            if (commonData.field_button_text !== "" && (commonData.field_button_text == "LEER EN ESPAÑOL")) {
              commonData.field_description = "Biomerics se compromete a enriquecer las comunidades donde trabajamos, vivimos y jugamos. Como parte de este compromiso, lanzamos en 2022 el programa Supérate como un recurso para madres solteras de la comunidad de Cartago, Costa Rica. El programa Supérate es un camino educativo de 18 meses para completar todos los requisitos de la escuela secundaria, con un trabajo garantizado en Biomerics al final del programa. Supérate empodera a las mujeres con los recursos educativos para mejorar sus conocimientos y habilidades, lo que beneficia directamente a sus familias. Con el apoyo del programa tanto de líderes locales como de ejecutivos corporativos, Biomerics ofrece becas a los participantes para cubrir las tasas de matrícula y otros gastos, además de organizar foros para ayudar a continuar motivando y empoderando a los participantes. Creemos que invertir en las madres tendrá un impacto positivo en las familias y las comunidades, y estamos entusiasmados de ampliar el programa en el futuro.";
              setShown(commonData.field_description);
              commonData.title = "Supérate: Empoderamiento a través de la educación";
              setSpanishTitle(commonData.title);
              commonData.field_button_text = "READ IN ENGLISH";
            } else if (commonData.field_button_text !== "" && commonData.field_button_text === "READ IN ENGLISH") {
              commonData.field_description = "Biomerics is committed to enriching the communities where we work, live and play. As part of this commitment, we launched the Supérate program in 2022 as a resource for single mothers in the Cartago, Costa Rica, community. The Supérate program is an 18-month educational path to completing all high school requirements, with a guaranteed job at Biomerics at the end of the program.Supérate empowers women with the educational resources to enhance their knowledge and skills, which directly benefits their families. With program support from both local leaders and corporate executives, Biomerics provides scholarships to participants to cover tuition fees and other expenses, as well as hosts forums to help continue to motivate and empower participants. We believe that investing in mothers will have a positive impact on families and communities, and we are excited to expand the program in the future.";
              setShown(commonData.field_description);
              commonData.title = "Supérate: Empowerment through education";
              setSpanishTitle(commonData.title);
              commonData.field_button_text = "LEER EN ESPAÑOL";
            } else {
              console.log("no translation available!");
            }
          };





          //For Quadra section Font

          const applyClassToSpans = (htmlContent: any) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlContent;
            const spans = tempDiv.querySelectorAll('span');
            spans.forEach((span) => {
              if (commonData.title === "QUADRA™") {
                span.classList.add('quadra-span-tag');
              }
            });

            // Return updated HTML content
            return tempDiv.innerHTML;
          };

          if (commonData.nid === "225" || commonData.nid === "232") {
            return (
              <Box key={index} className='get-in-touch-expert' pt={6} pb={6} style={{ background: commonData.field_bg_color }}>
                <Grid container>
                  <Grid item xs={12} xl={6} >
                    <Typography variant="h2" fontWeight="500" color="#ffffff" className='section-heading-expert'>
                      {commonData.title}
                    </Typography>

                  </Grid>
                  <Grid item xs={12} xl={6} display="flex" justifyContent={{ xs: "center", xl: "flex-end" }}>

                    {commonData.field_button_text && (
                      <Button
                        component={RouterLink}
                        // to={commonData.field_button_url}
                        to={commonData.field_button_url.replace(/&amp;/g, '&')}
                        variant="contained"
                        className='btn-orange'
                      >
                        {commonData.field_button_text}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )
          } else {
            return (
              <Box className='section' style={{ background: commonData.field_bg_color }} id={"section" + (commonData.nid)} key={index}>
                <Grid container spacing={6} sx={{ mt: '0 !important' }} className={number % 2 !== 0 ? "revers" : ""}>
                  <Grid item xs={12} lg={6} display='flex' justifyContent='center' flexDirection='column'>
                    <Box className="text-section" pb={{ xs: '20px', md: '64px' }} sx={{
                      pl: commonData.field_pd_left !== "" ? commonData.field_pd_left : "",
                      pr: commonData.field_pd_right !== "" ? commonData.field_pd_right : ""
                    }} >

                      {commonData.title !== "" && commonData.title !== "QUADRA™" &&
                        <Typography variant="h1" mb="12px" fontWeight="500" maxWidth={{ xs: '100%', lg: '691px' }}
                          color={{ color: commonData.field_title_fgcolor }} className='section-heading' style={bulletsColor}>
                          {commonData.title.split("amp;")}<br />
                          <span style={{ fontSize: '18px', display: 'inline-block', verticalAlign: 'top' }}>{commonData.field_title1}</span>
                        </Typography>
                      }
                      {console.log('commonData', commonData)}

                      {commonData.title !== "" && commonData.title === "QUADRA™" &&
                        <Typography variant="h1" mb="12px" fontWeight="600" fontFamily="Gotham" maxWidth={{ xs: '100%', lg: '691px' }}
                          color={{ color: commonData.field_title_fgcolor }} className='section-heading' style={bulletsColor}>
                          {commonData.title.split("amp;")}<br />
                          <span style={{ fontSize: '18px', display: 'inline-block', verticalAlign: 'top', fontFamily: "Gotham", fontWeight: "600" }}>{commonData.field_title1}</span>
                        </Typography>
                      }

                      <Box maxWidth={{ xs: '100%', lg: '655px' }} >
                        {field1 !== "" &&
                          <Typography mb="20px" fontSize='25px' color={{ color: commonData.field_title_fgcolor }}>
                            <div dangerouslySetInnerHTML={{ __html: field_description }} />
                          </Typography>
                        }
                        {field2 !== "" &&
                          <Typography mb="20px" fontSize='25px' color={{ color: commonData.field_title_fgcolor }}>
                            <div dangerouslySetInnerHTML={{ __html: field_description2 }} />
                          </Typography>
                        }
                        {list_1 !== "" &&
                          <List disablePadding className='custom-list' style={bulletsColor}>
                            {ShowlistData_1(list_1)}
                          </List>
                        }
                        {list_3 !== "" &&
                          <List disablePadding className='custom-list' style={bulletsColor}>
                            {ShowlistData_3(list_3)}
                          </List>
                        }
                        {commonData.title === "QUADRA™" && commonData.body !== '' ?

                          <Typography mb="20px" fontSize='25px'>
                            <div dangerouslySetInnerHTML={{ __html: applyClassToSpans(commonData.body) }} />
                          </Typography> :

                          <Typography mb="20px" fontSize='25px'>
                            <div dangerouslySetInnerHTML={{ __html: commonData.body }} />
                          </Typography>
                        }
                      </Box>
                      {commonData.field_button_text !== "" &&
                        commonData.field_button_type === 'video' &&
                        <Box mt={4}>
                          <Button
                            variant="contained"
                            id={commonData.nid}
                            onClick={(e) => handleDialogOpenTwo(e, commonData.field_video)}
                            className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                              commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                            sx={{ px: '24px' }}
                          >
                            {commonData.field_button_type === 'video' ? commonData.field_button_text.replace("&#039;", "'") : 'N'}
                          </Button>
                        </Box>
                      }

                      {commonData.field_button_text !== "" &&
                        commonData.field_button_text !== "READ IN ENGLISH" &&
                        commonData.field_button_text !== "LEER EN ESPAÑOL" &&
                        commonData.field_button_text !== "COMPARE QUADRA™ TO OTHER BRANDS" &&
                        commonData.field_button_type !== 'video' &&

                        <Box mt={4}>
                          <Button
                            variant="contained"
                            id={commonData.nid}
                            target={commonData.field_button_url.includes("https") ? "_blank" : ''}
                            href={checkButtonRedirect}
                            className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                              commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                            sx={{ px: '24px' }}
                          >
                            {commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                              commonData.field_button_text.split("amp;") : 'TO DO'}
                          </Button>
                        </Box>
                      }

                      {commonData.field_button_text !== "" &&
                        (commonData.field_button_text === "READ IN ENGLISH" || commonData.field_button_text === "LEER EN ESPAÑOL") &&
                        <Box mt={4}>
                          <Button
                            variant="contained"
                            id={commonData.nid}
                            className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                              commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                            sx={{ px: '24px' }}
                            onClick={() => handleButtonClick(commonData)}
                          >
                            {buttonText}
                            {commonData.field_button_type === 'video' || commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                              commonData.field_button_text.split("amp;") : 'TO DO'}
                          </Button>
                        </Box>
                      }


                      {commonData.field_question_button_1_url !== "" && (commonData.field_button_text_2 == "REVIEW SDS/TDS RECORDS" && commonData.field_button_text == "COMPARE QUADRA™ TO OTHER BRANDS") &&

                        <Box mt={4} display='flex' gap='16px' justify-content='flex-start' className="pre-btn-new">
                          <Button
                            variant="contained"
                            id={commonData.nid}
                            target={commonData.field_button_text.includes("https") ? "_blank" : ''}
                            href={checkButtonRedirect}
                            className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                              commonData.field_button_color === "#FAFAFA" ? 'btn-white' :

                                commonData.field_button_color === "#FFB801" ? 'quadra-btn-yellow' : ''}
                            sx={{ padding: "27px 15px" }}
                            onClick={() => handleButtonClick(commonData)}
                          >

                            {commonData.field_button_type === 'video' || commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                              commonData.field_button_text.split("amp;") : 'TO DO'}
                          </Button>

                          <Button
                            variant="contained"
                            id={commonData.nid}
                            target={commonData.field_question_button_1_url.includes("https") ? "_blank" : ''}
                            href={checkQuestionButtonRedirect}
                            className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                              commonData.field_button_color === "#FAFAFA" ? 'btn-white' :
                                commonData.field_button_color === "#FFB801" ? 'quadra-btn-yellow' : ''}
                            sx={{ padding: "27px 15px" }}
                            onClick={() => handleButtonClick(commonData)}
                          >

                            {commonData.field_button_type === 'video' || commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                              commonData.field_question_button_1_text.split("amp;") : 'TO DO'}
                          </Button>
                        </Box>
                      }


                      {commonData.field_button_type === 'video' &&
                        <Box className='video-modal'>
                          <Modal open={open} onClose={onCloseModal} modalId={commonData.nid}>
                            <ReactPlayer url={currentVideoUrl} width="100%" height="100%" />
                          </Modal>
                        </Box>
                      }
                    </Box>
                  </Grid>

                  <Grid item xs={12} lg={6} className="img-wrapper" >
                    {commonData.field_video !== "" ?
                      <Box className="w-100 h-100" display='flex' justifyContent={commonData.field_justify_content} alignItems={commonData.field_align_items !== "" ? commonData.field_align_items : 'center'}>
                        <Box className='video-wrapper' pt={padding_top} pb={padding_bottom} pl={padding_left} pr={padding_right}>
                          <ReactPlayer url={commonData.field_video} width='100%' height='100%' />
                        </Box>
                      </Box>
                      :
                      <Box className='w-100 h-100' display='flex' pt={padding_top} pb={padding_bottom} pl={padding_left} pr={padding_right} justifyContent={commonData.field_justify_content} alignItems={commonData.field_align_items !== "" ? commonData.field_align_items : 'center'}>

                        {
                          commonData.title === "QUADRA™" ? (
                            <img src={commonData.field_image_url_1} alt="" className='img-responsive-quadra' />
                          ) :
                            commonData.nid === "684" ? (
                              <img src={commonData.field_image_url_1} alt="" className='img-responsive-core-automation' />
                            ) :
                              commonData.nid === "683" ? (
                                <img src={commonData.field_image_url_1} alt="" className='img-responsive-automation' />
                              ) : (
                                <img src={commonData.field_image_url_1} alt="" className='img-responsive' />
                              )
                        }


                      </Box>
                    }

                  </Grid>

                </Grid>

              </Box>
            );

          }

        })}

      </>
    );

  }

  else {
    console.log("data [else] :", data);
    console.log("banner [else] :", banner);

    return (
      <>
        {/* Map over data from props */}

        {data.map((commonData: any, index: any) => {
          let number = index;
          let transparantImage = commonData.field_bg_image_url;

          const handleDialogOpenTwo = (e: any, videoUrl: string) => {
            if (e.target.id === commonData.nid) {
              setCurrentVideoUrl(videoUrl);
              setOpen(true);
            }
          };

          function decodeEntity(inputStr: any) {
            var textarea = document.createElement("textarea");
            textarea.innerHTML = inputStr;
            return textarea.value;
          }

          const padding_bottom = {
            paddingBottom: commonData.field_center_bottom_padding + '!important'
          };

          const padding_left = {
            paddingLeft: commonData.field_pd_image_left + '!important'
          };

          const padding_right = {
            paddingRight: commonData.field_pd_image_right + '!important'
          };

          const padding_top = {
            paddingTop: commonData.field_center_top_padding + '!important'
          };

          var list_1 = commonData.field_description2.split("\r\n");
          var field1 = commonData.field_description.replace("&gt;", ">");
          var field2 = commonData.field_description3.replace("&gt;", ">");
          var bulletsColor = { "--bullet-bg-color": commonData.field_bullets_fgcolor } as React.CSSProperties;

          const field_description = decodeEntity(field1);
          const field_description2 = decodeEntity(field2);
          const button_url = decodeEntity(commonData.field_button_url);

          var list_3 = commonData.field_description4.split("\r\n");
          var list_data = decodeEntity(list_3);

          const ShowlistData_1 = (list_1: any) => {
            return list_1.map((data: any, i: any) => (
              <>
                {data !== "" &&
                  <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
                    <ListItemText>{data.replace("&gt;", ">").split("amp;")}</ListItemText>
                  </ListItem>
                }
              </>
            ));
          };

          const ShowlistData_3 = (list_3: any) => {
            return list_3.map((data: any, i: any) => (
              <>
                {data !== "" &&
                  <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
                    <ListItemText>{data.replace("&gt;", ">").split("amp;")}</ListItemText>
                  </ListItem>
                }
              </>
            ));
          };

          var checkButtonRedirect = button_url.includes("https") ? button_url : button_url;

          const handleButtonClick = (commonData: any) => {
            if (commonData.field_button_text !== "" && (commonData.field_button_text == "LEER EN ESPAÑOL")) {
              commonData.field_description = "Biomerics se compromete a enriquecer las comunidades donde trabajamos, vivimos y jugamos. Como parte de este compromiso, lanzamos en 2022 el programa Supérate como un recurso para madres solteras de la comunidad de Cartago, Costa Rica. El programa Supérate es un camino educativo de 18 meses para completar todos los requisitos de la escuela secundaria, con un trabajo garantizado en Biomerics al final del programa. Supérate empodera a las mujeres con los recursos educativos para mejorar sus conocimientos y habilidades, lo que beneficia directamente a sus familias. Con el apoyo del programa tanto de líderes locales como de ejecutivos corporativos, Biomerics ofrece becas a los participantes para cubrir las tasas de matrícula y otros gastos, además de organizar foros para ayudar a continuar motivando y empoderando a los participantes. Creemos que invertir en las madres tendrá un impacto positivo en las familias y las comunidades, y estamos entusiasmados de ampliar el programa en el futuro.";
              setShown(commonData.field_description);
              commonData.title = "Supérate: Empoderamiento a través de la educación";
              setSpanishTitle(commonData.title);
              commonData.field_button_text = "READ IN ENGLISH";
            } else if (commonData.field_button_text !== "" && commonData.field_button_text === "READ IN ENGLISH") {
              commonData.field_description = "Biomerics is committed to enriching the communities where we work, live and play. As part of this commitment, we launched the Supérate program in 2022 as a resource for single mothers in the Cartago, Costa Rica, community. The Supérate program is an 18-month educational path to completing all high school requirements, with a guaranteed job at Biomerics at the end of the program.Supérate empowers women with the educational resources to enhance their knowledge and skills, which directly benefits their families. With program support from both local leaders and corporate executives, Biomerics provides scholarships to participants to cover tuition fees and other expenses, as well as hosts forums to help continue to motivate and empower participants. We believe that investing in mothers will have a positive impact on families and communities, and we are excited to expand the program in the future.";
              setShown(commonData.field_description);
              commonData.title = "Supérate: Empowerment through education";
              setSpanishTitle(commonData.title);
              commonData.field_button_text = "LEER EN ESPAÑOL";
            } else {
              console.log("no translation available!");
            }
          };

          if (commonData.nid === "738" || commonData.nid === "739") {
            console.log("commonData:", commonData);
            return (
              <Box key={index} className='get-in-touch-expert' pt={6} pb={6} style={{ background: commonData.field_bg_color }}>
                <Grid container>
                  <Grid item xs={12} xl={6} style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h2" fontWeight="500" color="#ffffff" className='section-heading-expert'>
                      {commonData.title}
                    </Typography>

                  </Grid>
                  <Grid item xs={12} xl={6} display="flex" justifyContent={{ xs: "center", xl: "flex-end" }}>

                    {commonData.field_button_text && (
                      <Button
                        type={commonData.field_button_type}
                        component={RouterLink}
                        to={commonData.field_button_url}
                        variant="contained"
                        className='btn-orange'
                      >
                        {commonData.field_button_text}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )
          }

          if (commonData.nid === "744" || commonData.nid === "745") {
            return (
              <Box key={index} className='get-in-touch-expert' pt={6} pb={6} style={{ background: commonData.field_bg_color }}>
                <Grid container>
                  <Grid item xs={12} xl={6} >
                    <Typography variant="h2" fontWeight="500" color="#ffffff" className='section-heading-expert'>
                      {commonData.title}
                    </Typography>

                  </Grid>
                  <Grid item xs={12} xl={6} display="flex" justifyContent={{ xs: "center", xl: "flex-end" }}>

                    {commonData.field_button_text && (
                      <Button
                        component={RouterLink}
                        // to={commonData.field_button_url}
                        to={commonData.field_button_url.replace(/&amp;/g, '&')}
                        variant="contained"
                        className='btn-orange'
                      >
                        {commonData.field_button_text}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )
          }

          return (
            <Box className='section' style={{ background: commonData.field_bg_color }} id={"section" + (commonData.nid)} key={index}>
              <Grid container spacing={6} sx={{ mt: '0 !important' }} className={number % 2 !== 0 ? "revers" : ""}>
                <Grid item xs={12} lg={6} display='flex' justifyContent='center' flexDirection='column'>
                  <Box className="text-section" pb={{ xs: '20px', md: '64px' }} sx={{
                    pl: commonData.field_pd_left !== "" ? commonData.field_pd_left : "",
                    pr: commonData.field_pd_right !== "" ? commonData.field_pd_right : ""
                  }} >
                    {commonData.title !== "" &&
                      <Typography variant="h1" mb="20px" fontWeight="500" maxWidth={{ xs: '100%', lg: '691px' }}
                        color={{ color: commonData.field_title_fgcolor }} className='section-heading' style={bulletsColor}>
                        {commonData.title.split("amp;")}<br />
                        {commonData.field_title1}
                      </Typography>
                    }
                    <Box maxWidth={{ xs: '100%', lg: '655px' }} >
                      {field1 !== "" &&
                        <Typography mb="20px" fontSize='25px' color={{ color: commonData.field_title_fgcolor }}>
                          <div dangerouslySetInnerHTML={{ __html: field_description }} />
                        </Typography>
                      }
                      {field2 !== "" &&
                        <Typography mb="20px" fontSize='25px' color={{ color: commonData.field_title_fgcolor }}>
                          <div dangerouslySetInnerHTML={{ __html: field_description2 }} />
                        </Typography>
                      }
                      {list_1 !== "" &&
                        <List disablePadding className='custom-list' style={bulletsColor}>
                          {ShowlistData_1(list_1)}
                        </List>
                      }
                      {list_3 !== "" &&
                        <List disablePadding className='custom-list' style={bulletsColor}>
                          {ShowlistData_3(list_3)}
                        </List>
                      }
                      <Typography mb="20px" fontSize='25px'>
                        <div dangerouslySetInnerHTML={{ __html: commonData.body }} />
                      </Typography>
                    </Box>

                    {commonData.field_button_text !== "" &&
                      commonData.field_button_type === 'video' &&
                      <Box mt={4}>
                        <Button
                          variant="contained"
                          id={commonData.nid}
                          onClick={(e) => handleDialogOpenTwo(e, commonData.field_video)}
                          className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                            commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                          sx={{ px: '24px' }}
                        >
                          {commonData.field_button_type === 'video' ? commonData.field_button_text.replace("&#039;", "'") : 'N'}
                        </Button>
                      </Box>
                    }

                    {commonData.field_button_text !== "" &&
                      commonData.field_button_text !== "READ IN ENGLISH" &&
                      commonData.field_button_text !== "LEER EN ESPAÑOL" &&
                      commonData.field_button_type !== 'video' &&
                      <Box mt={4}>
                        <Button
                          variant="contained"
                          id={commonData.nid}
                          target={commonData.field_button_url.includes("https") ? "_blank" : ''}
                          href={checkButtonRedirect}
                          className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                            commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                          sx={{ px: '24px' }}
                        >
                          {commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                            commonData.field_button_text.split("amp;") : 'TO DO'}
                        </Button>
                      </Box>
                    }

                    {commonData.field_button_text !== "" &&
                      (commonData.field_button_text === "READ IN ENGLISH" || commonData.field_button_text === "LEER EN ESPAÑOL") &&
                      <Box mt={4}>
                        <Button
                          variant="contained"
                          id={commonData.nid}
                          className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                            commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                          sx={{ px: '24px' }}
                          onClick={() => handleButtonClick(commonData)}
                        >
                          {buttonText}
                          {commonData.field_button_type === 'video' || commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                            commonData.field_button_text.split("amp;") : 'TO DO'}
                        </Button>
                      </Box>
                    }


                    {commonData.field_button_type === 'video' &&
                      <Box className='video-modal'>
                        <Modal open={open} onClose={onCloseModal} modalId={commonData.nid}>
                          <ReactPlayer url={currentVideoUrl} width="100%" height="100%" />
                        </Modal>
                      </Box>
                    }
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6} className="img-wrapper" >
                  {commonData.field_video !== "" ?
                    <Box className="w-100 h-100" display='flex' justifyContent={commonData.field_justify_content} alignItems={commonData.field_align_items !== "" ? commonData.field_align_items : 'center'}>
                      <Box className='video-wrapper' pt={padding_top} pb={padding_bottom} pl={padding_left} pr={padding_right}>
                        <ReactPlayer url={commonData.field_video} width='100%' height='100%' />
                      </Box>
                    </Box>
                    :
                    <Box className='w-100 h-100' display='flex' pt={padding_top} pb={padding_bottom} pl={padding_left} pr={padding_right} justifyContent={commonData.field_justify_content} alignItems={commonData.field_align_items !== "" ? commonData.field_align_items : 'center'}>
                      <img src={commonData.field_image_url_1} alt="" className='img-responsive' />
                    </Box>
                  }
                </Grid>
              </Grid>
            </Box>
          );
        })}

      </>
    );

  }

};

export default CommonRepetativeSections;
