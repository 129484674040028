import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdvancedCathetersSteerablesRFQResponse } from "../Service/AdvancedCathetersSteerablesRFQ";
import { ADVANCED_CATHETERS_CONTENT_RFQ_URL } from "../common/APi/apiurl";
import { formatAdvancedCathetersSteerablesRFQAddress, formatAdvancedCathetersSteerablesRFQHeader, formatAdvancedCathetersSteerablesRFQInfusion, formatAdvancedCathetersSteerablesRFQThankYou } from "../common/utils/utils";
import RFQHeadSection from "./sections/ComplexExtrusionRFQ/RFQHeadSection";
import RFQInfusionSection from "./sections/ComplexExtrusionRFQ/RFQInfusionSection";
import RFQFooterSection from "./sections/ComplexExtrusionRFQ/RFQFooterSection";
import AdvRFQHeadSection from "./sections/AdvancedCatheters&SteerablesRFQSection/AdvRFQSection";
import AdvRFQInfusionSection from "./sections/AdvancedCatheters&SteerablesRFQSection/AdvRFQInfusionSection";
import AdvRFQFooterSection from "./sections/AdvancedCatheters&SteerablesRFQSection/AdvRFQFooterSection";

const AdvancedCathetersSteerablesRFQ = () => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [LeadHeaderDetail, setLeadHeaderData] = useState<null | any>(null);
    const [LeadInfusionDetail, setLeadInfusionData] = useState<null | any>(null);
    const [LeadData, setLeadData] = useState<null | any>(null);
    const [LeadAddressDetail, setLeadAddressData] = useState<null | any>(null);
    const [LeadGenData, setLeadGenData] = useState<null | any>(null);
    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)


    const handelSuccessBanner = (flag: boolean) => {
        setShowSuccessBanner(flag)
    }
    const fetchResponse = async () => {
        const response = await AdvancedCathetersSteerablesRFQResponse(ADVANCED_CATHETERS_CONTENT_RFQ_URL);
        setPageContent(response)
        setLoading(false);
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let LeadHeaderData;
        let LeadInfusionData;
        let LeadData;
        let LeadAddressData;
        let LeadGenData;
        if (pageContent) {
            LeadHeaderData = formatAdvancedCathetersSteerablesRFQHeader(pageContent);
            LeadInfusionData = formatAdvancedCathetersSteerablesRFQInfusion(pageContent);
            LeadAddressData = formatAdvancedCathetersSteerablesRFQAddress(pageContent);
            LeadGenData = formatAdvancedCathetersSteerablesRFQThankYou(pageContent);
            // LeadGenData = formatContactUs(pageContent);
            setLeadHeaderData(LeadHeaderData);
            setLeadInfusionData(LeadInfusionData);
            // setLeadData(LeadData);
            setLeadAddressData(LeadAddressData);
            setLeadGenData(LeadGenData);
        }
    }
    useEffect(() => {
        fetchResponse();
    }, []);
    useEffect(() => {
        formatData();
    }, [pageContent])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <AdvRFQHeadSection data={LeadHeaderDetail} />
                    <AdvRFQInfusionSection data={LeadInfusionDetail} handelSuccessBannerCallback={handelSuccessBanner} />
                    <AdvRFQFooterSection data={LeadGenData} showBanner={showSuccessBanner} />
                </React.Fragment >
            }
        </>
    );
}

export default AdvancedCathetersSteerablesRFQ